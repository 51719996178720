<!--  -->
<template>
  <div class="container">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="iscontainer"
    >
        <div  v-for="(item,index) in list" :key="index" class="itemList">
            <div @click="toDetail(item.id)">
                <img :src="item.content.infoPic" class="item-img">
            </div> 
            <div class="item-right">
                <div @click="toDetail(item.id)">
                    <div class="item-title">{{item.content.infoName}}</div>
                    <div class="item-address"  v-if="item.content.phone && topicSymbol!='min-scenery-list'">电话：{{item.content.phone}}</div>  
                    <div class="item-address"  v-if="item.content.address">地点：{{item.content.address}}</div>
                
                </div> 
                <div class="item-icon">
                    <div class="item-icon-yan">
                        <img src="../../static/yan.png">
                        <span>{{item.readCount>999?'999+':item.readCount}}</span>
                    </div>
                    <!-- <div class="item-icon-zan" @click="onzan(item,index)">
                        <img src="../../static/ac_zan.png" v-if="item.iszan">
                        <img src="../../static/zan.png" v-else>
                        <span>{{item.praiseCount>999?'999+':item.praiseCount}}</span>
                    </div> -->
                </div>
            </div> 

        </div>
    </van-list>
    <div class="body-view" v-if="!iscontainer">
        <img src="../../static/null.png">
        <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import { List , Toast } from 'vant';

export default {
    data () {
        return {
            list: [],
            loading: false,
            finished: false,
            topicSymbol:'min-online-info-list',
            iscontainer:true,
            isLoadmore: false,
            openId:'',
            page: 0,
            size:10,
        };
    },
    components: {
      [List.name]: List,
      [Toast.name]: Toast,
    },
    created() {
        this.openId = this.$route.query.openId;
    },
    methods: {
        onLoad() {
            // 异步更新数据
            this.page +=1;
            let postData= {
                topicSymbol: this.topicSymbol,
                page: this.page,
                size: this.size,
            }
            console.log(postData);
            this.$api.getContentByPage(postData).then(res=>{
                // 加载状态结束
                this.loading = false;
                if (res.data.data.records.length == 0) {
                    this.iscontainer = false;
                } else {
                    res.data.data.records.forEach(v => {
                        Object.assign(v, { iszan: false })
                    })
                    this.list = res.data.data.records
                    if (res.data.data.records.length < 10) {
                        this.finished = true;
                    }
                };            
            })  ;        
        },
        onzan(module,index){
            console.log('点赞');
            // 是否点赞
            if (!module.iszan){
                // 异步更新数据
                let postData= {
                    contentId:module.id,
                    topicSymbol: this.topicSymbol,
                    userId:this.openId
                }
                this.$api.contentPraise(postData).then(res=>{
                    // 加载状态结束
                    if(res.data.code=='10000'){
                        // var str = "imgUrls[" + index + "].iszan";
                        // var praiseCount = "imgUrls[" + index + "].praiseCount";
                        // that.setData({ [str]: true, [praiseCount]: module.praiseCount+1 });
                        this.list[index].iszan = true ;
                        this.list[index].praiseCount = module.praiseCount+1 ;
                        Toast.success('点赞成功');
                    }else{
                        Toast('您已点过赞啦！');
                    }            
                })  
            }else{
                Toast('您已点过赞啦！');
            }
        },
        toDetail(id){
            this.$router.push({
                path: "/NewsDatail",
                query: {
                    openId: this.openId,
                    contentId:id,
                }
            });
        }
    },
    
}

</script>
<style scoped>
.container{
  width: 10rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
}
.itemList{
  width: 100%;
  height:3.06rem;
  box-shadow:0px 2px 12px 0px rgba(0,0,0,0.14);
  border-radius:0.4rem;
  margin-top:0.4rem;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-wrap:wrap; 
  position: relative;
}
.item-img{
  height:3.06rem;
  width: 3.2rem;
}
.item-right{
  width: 5.2rem;
  padding: 0 0.4rem;
}
.item-title{
  color: #222;
  font-size: 0.4rem;
  line-height: 0.826rem;
  width: 5.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item-address{
  font-size: 0.346rem;
  line-height: 0.506rem;
  color: #888888;
  width: 5.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.item-icon{
  display: flex;
  flex-wrap:wrap; 
  width: 2.53rem;
  height: 0.48rem;
  position: absolute;
  right: 0;
  bottom: 0.293rem;
}
.item-icon-yan{
  height: 0.48rem;
  float: left;
  margin-left:1.1rem;

}
.item-icon-yan img{
  width: 0.48rem;
  height: 0.48rem;
  float: left;
}
.item-icon-yan span{
  font-size: 0.293rem;
  color: #222222;
  line-height: 0.48rem;
  text-indent: 2px;
  float: left;
  width: 0.93rem;
  display: inline-block;
}
.item-icon-zan{
  height: 0.48rem;
  float: left;
  cursor: pointer;
}
.item-icon-zan img{
  width: 0.48rem;
  height: 0.48rem;
  float: left;
}
.item-icon-zan span{
  font-size: 0.293rem;
  color: #222222;
  float: left;
  line-height: 0.48rem;
  text-indent: 2px;
  display: inline-block;
}
.isLoadmore{
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size:0.293rem;
  color: #888888;
  text-align: center;
}
.body-view img{
  width: 3.16rem;
  height:  3.16rem;
  margin: 4rem 0 0.32rem 3.2rem;
}
.body-view p{
  font-size: 0.373rem;
  color: #888888;
  line-height: 0.53rem;
  text-align: center
}
</style>